<template>
  <div class="security-wrapper">
    <d-search class="d-search"
              :searchData="searchData"
              @searchEvent="searchList" @resetEvent="resetEvent"/>
    <div class="content">
      <div class="content_title">
        <el-button type="primary" size="small" icon="el-icon-plus" @click="add">新建</el-button>
      </div>
      <d-table :columnData="columnData"
               :tableData="tableData"
               @compile="edit"
               @removeEvent="delete_data"></d-table>
      <d-paging :pager="pager"
                style="marginTop:50px;"
                @change="searchList" />
    </div>
    <el-dialog :title="editType?'编辑':'新增'" :visible.sync="dialogVisible" width="400">
      <div class="dialog-content">
        <el-form ref="securityForm" :model="form" label-width="90" :rules="rules"  size="medium">
          <el-form-item label="姓名" prop="name">
            <el-input  v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="mobile">
            <el-input v-model="form.mobile"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="form.comment"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="footer">
        <el-button size="medium" @click="dialogVisible=false">取消</el-button>
        <el-button size="medium" type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import DSearch from "@/components/d-search";
import DTable from "@/components/d-table";
import DPaging from "@/components/d-paging";
import {validatePhone} from "@/utils/validator";
import {deepClone} from "../../../components/dynamicForm/utils/utils";
export default {
  name: "security",
  components: {DPaging, DTable, DSearch},
  data() {
    return {
      pager: {
        count: 0,
        page: 1,
        rows: 10
      },
      searchData: {
        searchItem: [
          {
            label: "姓名",
            value: "name",
            type: "commonInput",
            placeholder: "请输入"
          }
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        },
        params: {
          name: ''
        }
      },
      columnData: [
        {prop: "name", label: "姓名"},
        {prop: "mobile", label: "联系电话"},
        {prop: "comment", label: "备注"},
        {prop: "createTime", label: "添加时间"},
        {prop: "operate", label: "操作", width:150, type:{
            compile:true,
            remove:true
          }}
      ],
      tableData: [],
      form:{},
      editType:'', // 0 新增  1 编辑
      rules:{
        name:[
            this.$formValidator.empty('姓名')
        ],
        mobile:[
            this.$formValidator.empty('电话'),
            {validator:validatePhone,trigger: 'blur'}
        ]
      },
      dialogVisible:false,

    }
  },
  created(){
    this.searchList();
  },
  methods:{
    searchList(){
      this.$api.sysapi.securityList(this.getParam()).then(({data})=>{
        this.pager.count = data.total;
        this.tableData = data.list;
      })
    },

    resetEvent(){
      this.pager = {
        ...this.pager,
        page:1,
        rows:10
      }
      this.searchList()
    },

    clearValid(){
      this.$nextTick(()=>{
        this.$refs.securityForm.clearValidate();
      })
    },

    add(){
      this.form = {};
      this.editType = 0;
      this.dialogVisible = true;
      this.clearValid()
    },

    edit(e){
      this.form = deepClone(e);
      this.editType = 1;
      this.dialogVisible = true;
      this.clearValid()
    },

    delete_data(e){
      this.$api.sysapi.securityDel({id:e.id}).then(({code,msg})=>{
        this.$message.success(msg)
        this.searchList();
      })
    },

    getParam(){
      return {
        pageNum:this.pager.page,
        pageSize:this.pager.rows,
        name:this.searchData.params.name
      }
    },

    confirm(){
      this.$refs.securityForm.validate((valid)=>{
        if(valid){
          const handle = !this.editType?this.$api.sysapi.securityAdd(this.form):this.$api.sysapi.securityEdit(this.form);
          handle.then(({code,msg})=>{
            this.$message.success(msg)
            this.searchList();
            this.dialogVisible = false;
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  .content_title{
    margin: 10px 20px;
    box-sizing: border-box;
    .el-button{
      float: right;
    }
  }
  .el-table {
    margin-bottom: 20px;
    flex: 1;
    overflow-y: auto;
  }
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  padding: 0 30px;
  text-align: center;
}
.dialog-content{
  width: 95%;
  margin: auto;
}
.footer{
  float: right;
}
</style>